export interface Image {
    baseURL: string;
    width?: number;
    height?: number;
    dimensionsProcessed?: number[];
    blurHash?: string;
    aspectRatio?: number;
};

export function getAppropriateImage(image: Image, width: number, height: number): string {
    if(!image.dimensionsProcessed) {
        return image.baseURL;
    }
    const drivingDimension =
      (width > height ? width : height) * window.devicePixelRatio;
    const selectedDimension = image.dimensionsProcessed.find(
      (x) => x > drivingDimension
    ) ?? image.dimensionsProcessed[image.dimensionsProcessed.length - 1];
    return image.baseURL + "-" + selectedDimension;
}