import React, { Suspense } from "react";
import { IComponent } from "../../../Component";

export const FORM_MZR = "form_mzr";

export interface FormMZRSpec {
  type: typeof FORM_MZR;
}

const LazyComponent = React.lazy(() => import("./FormMZRLazy"));

export const FormMZRComponent: IComponent<FormMZRSpec> = {
  component: () => (
    <Suspense>
      <LazyComponent />
    </Suspense>
  ),
};
