import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import Carousel, {
  CommonProps,
  Modal,
  ModalGateway,
  ViewType,
} from "react-images";
import {
  default as _Gallery,
  GalleryI,
  RenderImageProps,
} from "react-photo-gallery";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { getAppropriateImage } from "./image";
import { ImageRenderer } from "./ImageRenderer";

export const GALLERY = "gallery";

interface Image {
  baseURL: string;
  width: number;
  height: number;
  dimensionsProcessed?: number[];
  blurHash?: string;
  aspectRatio?: number;
}
export interface GallerySpec {
  type: typeof GALLERY;
  images: Image[];
}

export const ImagePlaceholder = styled.div`
  border: 2px dashed #888;
  border-radius: 15px;
  background: white;
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
`;

export const ImageContainer = styled.div`
  display: block;
  max-width: 100%;
`;

export const Photo = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
}: PropsWithChildren<RenderImageProps<Image>>) => {
  return (
    <div
      style={{
        position: "relative",
        height: photo.height,
        width: photo.width,
        margin: margin,
      }}
    >
      <ImageRenderer
        image={photo}
        onClick={(event) => {
          onClick?.(event, { index });
        }}
      />
    </div>
  );
};

const Gallery = _Gallery as unknown as GalleryI<Image>;

const Component = (props: { spec: GallerySpec }) => {
  const [lighboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = useCallback((event: any, { index }: any) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxOpen(false);
  };

  if (props.spec.images.length >= 1) {
    return (
      <>
        <ImageContainer>
          <Gallery
            onClick={openLightbox}
            targetRowHeight={150}
            photos={props.spec.images.map((x) => ({ ...x, src: x.baseURL }))}
            renderImage={(stuff) => <Photo {...stuff} />}
          />
        </ImageContainer>
        {/* @ts-ignore */}
        <ModalGateway>
          {lighboxOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={props.spec.images.map(
                  (i): ViewType => ({
                    source: {
                      download: i.baseURL,
                      regular: getAppropriateImage(
                        i,
                        window.innerWidth,
                        window.innerHeight
                      ),
                      fullscreen: getAppropriateImage(
                        i,
                        window.screen.availWidth,
                        window.screen.availHeight
                      ),
                    },
                  })
                )}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
  return <div>Missing Images</div>;
};

export const GalleryComponent: IComponent<GallerySpec> = {
  component: Component,
};
