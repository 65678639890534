import React, { Suspense, useEffect, useState, useMemo} from "react";
import {
  NotFoundError,
  PermissionDeniedError,
  UnavailableError,
  usePage,
} from "./api";
import { makeSystemPage, pageName } from "./config";
import { useUser } from "./oidc";
import { Page } from "./Page";
import { PageContext } from "./PageContext";
import { useLocation } from "./router";
import { SITE_MAP } from "./SiteMap";
import {
  LOGIN_CALLBACK,
  NOT_AUTHORIZED_COMPONENT,
  NOT_FOUND_COMPONENT,
} from "./standardcomponents";

const EditPage = React.lazy(() => import("./EditPage"));

const App: React.FC = () => {
  const location = useLocation();

  if (location === "/sitemap")
    return <Page spec={makeSystemPage({ type: SITE_MAP })} />;
  if (location === "/login/callback")
    return <Page spec={makeSystemPage({ type: LOGIN_CALLBACK })} />;

  return <RenderPage location={location} />;
};

function RenderPage(props: { location: string }): JSX.Element {
  const pageState = usePage(props.location);

  const pageTitle = pageState.page?.title;
  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle + " - " + pageName;
    }
  }, [pageTitle]);
  const user = useUser();
  const canEdit = !!pageState.page?.accessPolicy && user !== null;
  const [isEditing, setEditing] = useState(false);
  const contextValue = useMemo(() => ({
    isEditing: pageState.page !== null && isEditing,
    setEditing,
    canEdit,
    origin: window.location.origin,
  }), [pageState.page, isEditing]);
  let errorRender: JSX.Element | null = null;
  if (pageState.error instanceof UnavailableError) {
    errorRender = (
      <Page
        spec={makeSystemPage({
          type: NOT_FOUND_COMPONENT,
          path: props.location,
        })}
      />
    );
  }
  if (pageState.error instanceof NotFoundError) {
    errorRender = (
      <Page
        spec={makeSystemPage({
          type: NOT_FOUND_COMPONENT,
          path: props.location,
        })}
      />
    );
  }
  if (pageState.error instanceof PermissionDeniedError) {
    errorRender = (
      <Page
        spec={makeSystemPage({
          type: NOT_AUTHORIZED_COMPONENT,
          sufficientAMRs: [],
        })}
      />
    );
  }
  
  return (
    <PageContext.Provider
      value={contextValue}
    >
      {pageState.page ? (
        isEditing ? (
          <Suspense fallback={<Page spec={pageState.page.content} />}>
            <EditPage page={pageState.page} />
          </Suspense>
        ) : (
          <Page spec={pageState.page.content} />
        )
      ) : (
        errorRender
      )}
    </PageContext.Provider>
  );
}

export default App;
