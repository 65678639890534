import React, { HTMLInputTypeAttribute } from "react";
import { useCallback, useState } from "react";
import {
  Controller,
  FieldValues,
  FormState,
  UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components/macro";

export const ErrorMessageBox = styled.div`
  color: red;
`;
export default function CustomInput(props: {
  name: string;
  required?: boolean;
  label?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?: InputMode;
  register: UseFormRegister<FieldValues>;
  errors: any;
  control: any;
}) {
  return (
    <div>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <Input
            ref={ref}
            name={name}
            label={props.label}
            type={props.type}
            inputMode={props.inputMode}
            value={value}
            onChange={(e) => onChange(e)}
          />
        )}
        rules={{ required: props.required ?? false }}
        defaultValue=""
      />
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </div>
  );
}

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const InputElement = styled.input<{ active: boolean; focused: boolean }>`
  line-height: 1.5;
  padding: 1rem;
  padding-top: ${(p) => (p.active ? "1.5rem" : "1rem")};
  padding-bottom: ${(p) => (p.active ? "0.5rem" : "1rem")};
  display: block;
  border: 2px solid;
  border-color: ${(p) => (p.focused ? "#009ee0" : "#949494")};
  border-radius: 2px;
  order: 1;
  outline: 0;
  :placeholder {
    color: #666;
    opacity: 1;
  }
`;

const InputLabel = styled.label<{ active: boolean }>`
  position: absolute;
  top: ${(p) => (p.active ? "0.8rem" : "1.2rem")};
  left: 1.125rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 1.5rem;
  background: #fff;
  transition: top 0.1s ease;
  color: ${(p) => (p.active ? "#000" : "#666")};
  outline: none;
  font-weight: ${(p) => (p.active ? "bold" : "normal")};
  font-size: ${(p) => (p.active ? "12px" : "inherit")};
`;

//https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
type InputMode =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";

interface inputProps {
  name: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?: InputMode;
  value: string;
  autoFocus?: boolean;
  onChange?: (newValue: string) => void;
  onEnter?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, inputProps>(
  (props: inputProps, ref) => {
    const [focused, setFocused] = useState(false);

    const active = focused || props.value !== "";
    const { onChange, onEnter } = props;
    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(e.currentTarget.value),
      [onChange]
    );
    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === "Enter" ? onEnter?.() : undefined,
      [onEnter]
    );

    const onFocus = useCallback(() => setFocused(true), []);
    const onBlur = useCallback(() => setFocused(false), []);

    return (
      <InputBox>
        <InputElement
          name={props.name}
          type={props.type}
          onFocus={onFocus}
          onBlur={onBlur}
          active={active}
          inputMode={props.inputMode}
          focused={focused}
          value={props.value}
          autoFocus={props.autoFocus}
          onChange={onChangeInput}
          onKeyDown={onKeyDown}
          ref={ref}
        />
        <InputLabel active={active} htmlFor={props.name}>
          {props.label ? props.label : props.name}
        </InputLabel>
      </InputBox>
    );
  }
);
