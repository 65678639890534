import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { faGit } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components/macro";

const FooterContainer = styled.nav`
  background: #222544; // #1128fa;
  color: white;
  font-weight: bold;
  padding: 5px;

  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  //box-shadow: 0px 0 3px 0px rgb(0, 0, 0);

  @media (min-width: 768px) {
    padding-left: 180px;
    padding-right: 100px;
  }

  @media (max-width: 1096px) {
    padding-left: 160px;
    padding-right: 80px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const IconFontAwesome = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

export function BSRFooter(): JSX.Element {
  return (
    <FooterContainer>
      <div>
        <IconFontAwesome icon={faCopyright} /> Berufsschule Rüti
      </div>
      <div></div>
      <div>
        <IconFontAwesome icon={faGit} /> 0f37e6e2
      </div>
    </FooterContainer>
  );
}
