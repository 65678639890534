import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { Vertical, VerticalDI, verticalDI, VerticalSpec } from "./Vertical";

export const COLUMN_LAYOUT = "column_layout";

export interface ColumnLayoutSpec {
  type: typeof COLUMN_LAYOUT;
  columns: VerticalSpec[];
  dynamicSized: boolean;
  spaceBetween: number;
  verticalSeparators: boolean;
  centerSeparator: boolean;
}

const Container = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
  width: 100%;
`;

const Column = styled.div<{ dynamicallySized: boolean }>`
  ${(props) => (props.dynamicallySized ? "" : "flex-basis: 0; flex-grow: 1;")}
`;

const joinJsxArray = (arr: JSX.Element[], joinWith: JSX.Element) => {
  if (!arr || arr.length < 2) {
    return arr;
  }

  const out = [arr[0]];
  for (let i = 1; i < arr.length; i += 1) {
    out.push(joinWith, arr[i]);
  }
  return out;
};

const VerticalSeparatorContainer = styled.div<{
  spaceBetween: number;
  center: boolean;
}>`
  @media (min-width: 768px) {
    width: ${(props) => props.spaceBetween + "px"};

    ${(props) =>
      props.center ? "display: flex; justify-content: center;" : ""}
  }
`;

const VerticalSeparator = styled.div`
  @media (min-width: 768px) {
    display: inline-block;
    border-left: 2px solid #288acc;
    width: 2px;
    height: 100%;
  }
`;

export const ColumnLayout = (props: VerticalDI<ColumnLayoutSpec>) => {
  const VerticalDIComp = props.Vertical;
  return (
    <Container>
      {joinJsxArray(
        props.spec.columns.map((spec) => (
          <Column dynamicallySized={props.spec.dynamicSized}>
            <VerticalDIComp spec={spec} />
          </Column>
        )),
        <VerticalSeparatorContainer
          spaceBetween={props.spec.spaceBetween}
          center={props.spec.centerSeparator}
        >
          {props.spec.verticalSeparators ? <VerticalSeparator /> : null}
        </VerticalSeparatorContainer>
      )}
    </Container>
  );
};

export const ColumnLayoutComponent: IComponent<ColumnLayoutSpec> = {
  component: verticalDI(ColumnLayout, Vertical),
};
