import {
  faBars,
  faLifeRing,
  faLock,
  faSignOutAlt,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components/macro";
import { signInAndRedirect, signOut, useUser } from "../oidc";
import { Link } from "../router";
import { bsrBlue } from "./bsr-defs";
import { ReactComponent as CloudBase } from "./cloud.svg";
import { LoginButton } from "./LoginButton";
import { ReactComponent as MailBase } from "./mail.svg";
import { ReactComponent as MensaBase } from "./mensa.svg";
import { ReactComponent as MoodleBase } from "./moodle.svg";
import { ReactComponent as BrandBase } from "./zuerich-logo.svg";
import { ReactComponent as LogoBase } from "./zuerich-logo2.svg";

const Brand = styled(BrandBase)`
  height: 75px;
  padding: 10px 0;
  padding-left: 20px;
  width: 70px;
  @media (min-width: 768px) {
    height: 130px;
    width: 120px;
  }
  @media (max-width: 1096px) {
    padding-left: 0px;
  }

  @media (max-width: 360px) {
    height: 44px;
    width: 39px;
  }
`;

const Logo = styled(LogoBase)`
  display: block;
  width: 20px;
  height: 20px;
  @media (min-width: 768px) {
    width: 27px;
    height: 27px;
  }

  @media (max-width: 360px) {
    height: 14px;
    width: 14px;
  }
`;

const MailIcon = styled(MailBase)`
  height: 21px;
  width: 28px;

  @media (max-width: 360px) {
    height: 19px;
  }
`;

const CloudIcon = styled(CloudBase)`
  height: 22px;
  width: 31px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const MoodleIcon = styled(MoodleBase)`
  height: 22px;
  width: 33px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const MensaIcon = styled(MensaBase)`
  height: 22px;
  width: 24px;

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const Container = styled.nav`
  display: flex;
  flex-shrink: 0;
`;

const LogoContainer = styled.nav`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    height: 150px;
  }
`;

const MainNavContainer = styled.div`
  margin-left: 10px;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 60px;
  @media (min-width: 768px) {
    margin-right: 80px;
  }

  @media (max-width: 1096px) {
    margin-right: 60px;
  }

  @media (max-width: 450px) {
    margin-right: 0px;
  }
`;

const BrandText = styled.div`
  font-weight: 900;
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 38px;
    margin-top: 6px;
  }
`;

const NavIconsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 8px;
  padding-left: 3px;
  a {
    height: 16px;
  }
  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
    a {
      height: 22px;
    }
  }
`;

const SubNavContainer = styled.div`
  z-index: 10;
  @media (min-width: 768px) {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    padding: 0 10px;
    display: none;
    background: white;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const RootNavItem = styled.div`
  font-weight: 900;
  font-size: 28px;
  padding-bottom: 10px;
  padding-right: 20px;

  @media (max-width: 1056px) {
    font-size: 24px;
  }
`;

const SubNavItem = styled.div`
  font-weight: 900;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const RootNavContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding-left: 3px;
    display: flex;
    flex-flow: wrap;
    ${RootNavItem}:hover > ${SubNavContainer}, ${SubNavContainer}:hover {
      visibility: visible;
      opacity: 1;
      display: blocK;
      box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 3px 3px 1px rgba(60, 64, 67, 0.16);
    }
  }
`;

const SubMenuSeparator = styled.div`
  @media (min-width: 768px) {
    border-bottom: 1px solid #888;
    width: 100%;
  }
`;

const NavIcon = styled.div`
  padding-right: 10px;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.1;
  }
  @media (max-width: 360px) {
    padding-right: 6px;
  }
`;

const IconFontAwesome = styled(FontAwesomeIcon)`
  font-size: 22px;
  @media (max-width: 768px) {
    height: 22px;
  }

  @media (max-width: 360px) {
    height: 20px;
  }
`;

const MobileContainer = styled.div`
  height: 100%;
  margin-right: 20px;
  margin-top: 32px;
`;

const MobileOpenContainer = styled.button`
  display: block;
  background: none;
  border: none;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 394px) {
    margin-top: 5px;
  }
`;

function RenderRootNav(): JSX.Element {
  return (
    <>
      <RootNavItem>
        <Link activeColor={bsrBlue} path="/dienstleistung">
          Dienstleistung
        </Link>
      </RootNavItem>
      <RootNavItem>
        <Link activeColor={bsrBlue} path="/maschinenbau">
          Maschinenbau
        </Link>
      </RootNavItem>
      <RootNavItem>
        <Link activeColor={bsrBlue} path="/weiterbildung">
          Weiterbildung
        </Link>
      </RootNavItem>
      <RootNavItem>
        Schulangaben
        <SubNavContainer>
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/kontakt">
              Lageplan &amp; Kontakt
            </Link>
          </SubNavItem>
          <SubMenuSeparator />
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/infos">
              Infos &amp; Downloads
            </Link>
          </SubNavItem>
          <SubMenuSeparator />
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/personen">
              Organisation
            </Link>
          </SubNavItem>
          <SubMenuSeparator />
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/team">
              Team
            </Link>
          </SubNavItem>
          <SubMenuSeparator />
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/vermietungen">
              Vermietungen
            </Link>
          </SubNavItem>
          <SubMenuSeparator />
          {/* <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/bildergalerie">
              Bildergalerie
            </Link>
          </SubNavItem>
          <SubMenuSeparator /> */}
          <SubNavItem>
            <Link activeColor={bsrBlue} path="/schulangaben/jahresberichte">
              Jahresberichte
            </Link>
          </SubNavItem>
        </SubNavContainer>
      </RootNavItem>
    </>
  );
}

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoginContainer = styled.div`
  display: none;

  box-sizing: border-box;
  height: 25px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const User = styled.div`
  display: none;
  font-size: 22px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const UserSubtext = styled.div`
  font-size: 14px;
  color: gray;
`;

const UserIcon = styled(FontAwesomeIcon)`
  border-radius: 50%;
  font-size: 44px;
  overflow: hidden !important;
  box-sizing: border-box;
  min-width: 44px;
  background-color: lightgray;
`;

const UserPicture = styled.img`
  border-radius: 50%;
  height: 44px;
  width: 44px;
`;

function UserProfilePicture(props: { url?: string }): JSX.Element {
  return props.url ? (
    <UserPicture alt="Profile" src={props.url} />
  ) : (
    <UserIcon icon={faUser} />
  );
}

const LogoutButton = styled.button`
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border: none;
  height: 44px;
  background: none;
`;

const UserName = styled.div`
  margin-left: 10px;
  font-size: 18px;
`;

export function BSRNavbar(props: { onOpenSidebar: () => void }): JSX.Element {
  const user = useUser();
  return (
    <Container>
      <LogoContainer>
        <Link path="/">
          <Brand />
        </Link>
        <Link path="/">
          <Logo />
        </Link>
      </LogoContainer>
      <MainNavContainer>
        <NavIconsContainer>
          <Link path="https://webmail.bsrueti.ch/">
            <NavIcon>
              <MailIcon height={22} />
            </NavIcon>
          </Link>
          <Link path="https://cloud.bsrueti.ch/">
            <NavIcon>
              <CloudIcon height={22} />
            </NavIcon>
          </Link>
          <Link path="https://moodle.bsrueti.ch/">
            <NavIcon>
              <MoodleIcon height={22} />
            </NavIcon>
          </Link>
          <Link path="https://bsrueti.sv-restaurant.ch/">
            <NavIcon>
              <MensaIcon height={22} />
            </NavIcon>
          </Link>
          <Link path="/beratung">
            <NavIcon>
              <IconFontAwesome icon={faLifeRing} />
            </NavIcon>
          </Link>
          {user === null ? (
            <NavIcon>
              <IconFontAwesome
                icon={faLock}
                onClick={() => signInAndRedirect("/intern")}
              />
            </NavIcon>
          ) : (
            <Link path="/intern">
              <NavIcon>
                <IconFontAwesome icon={faUnlockAlt} />
              </NavIcon>
            </Link>
          )}
        </NavIconsContainer>
        <MiddleContainer>
          <BrandText>
            <Link path="/">Berufsschule Rüti</Link>
          </BrandText>
          <LoginContainer>
            {user === null ? (
              <LoginButton />
            ) : (
              <User>
                <UserProfilePicture url={user.picture} />
                <UserName>
                  <div>{user.name}</div>
                  <UserSubtext>Angemeldet</UserSubtext>
                </UserName>
                <LogoutButton>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    onClick={() => signOut()}
                  />
                </LogoutButton>
              </User>
            )}
          </LoginContainer>
        </MiddleContainer>
        <RootNavContainer>
          <RenderRootNav />
        </RootNavContainer>
      </MainNavContainer>
      <MobileContainer>
        <MobileOpenContainer onClick={props.onOpenSidebar}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </MobileOpenContainer>
      </MobileContainer>
    </Container>
  );
}

const SideNavbarContainer = styled.div`
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
`;

const SideNavbarLoginButton = styled.div`
  align-self: flex-start;
  margin-top: auto;
`;

export function BSRNavbarSide(props: {
  onCloseSidebar: () => void;
}): JSX.Element {
  const user = useUser();
  return (
    <SideNavbarContainer>
      <RenderRootNav />
      <SideNavbarLoginButton>
        {user === null ? <LoginButton /> : <span>{user.name}</span>}
      </SideNavbarLoginButton>
    </SideNavbarContainer>
  );
}
