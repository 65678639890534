import React, { useEffect } from "react";
import { FieldValues, FormState, UseFormRegister } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components/macro";

export const RadioLabel = styled.label`
  margin-right: 16px;
  line-height: 1.5;
`;

export const RadioTitle = styled.label`
  font-size: 14px;
  //font-family: HelveticaNowBlack; "Arial Black"; Helvetica; sans-serif;
  font-weight: bold;
`;

export const RadioBox = styled.div`
  margin-top: 15px;
`;

export const RadioOrientation = styled.div<{ horizontal: boolean }>`
  display: ${(p) => (p.horizontal ? "flex" : "box")};
  flex-flow: column;
`;

const ErrorMessageBox = styled.div`
  color: red;
`;

export default function CustomRadio(props: {
  name: string;
  required: boolean;
  label?: string;
  type?: string;
  values: string[];
  horizontal?: boolean;
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
  default?: string;
  setValue?: (
    name: string,
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  style?: React.CSSProperties;
}) {
  useEffect(() => {
    if (props.default) {
      if (!props.formState.dirtyFields[props.name]) {
        props.setValue?.(props.name, props.default, {
          shouldValidate: false,
          shouldDirty: true,
        });
      }
    }
  }, [props.formState, props.name, props.default]);

  return (
    <RadioBox style={props.style}>
      <RadioTitle>{props.label ? props.label : props.name}</RadioTitle>
      <RadioOrientation horizontal={props.horizontal ?? false}>
        {props.values.map((v) => (
          <RadioLabel key={"Label_" + v}>
            <input
              type="radio"
              value={v}
              key={v}
              {...props.register(props.name, { required: props.required })}
            />
            {v}
          </RadioLabel>
        ))}
      </RadioOrientation>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </RadioBox>
  );
}
