import { IComponent } from "../Component";
import { SiteMapComponent, SiteMapSpec, SITE_MAP } from "../SiteMap";
import {
  LoginCallbackSpec,
  LOGIN_CALLBACK,
  NotAuthorizedComponentSpec,
  NotFoundComponentSpec,
  NOT_AUTHORIZED_COMPONENT,
  NOT_FOUND_COMPONENT,
} from "../standardcomponents";
import {
  BSRCalendarComponent,
  BSRCalendarSpec,
  BSR_CALENDAR,
} from "./BSRCalendar";
import { BSRMapComponent, BSRMapSpec, BSR_MAP } from "./BSRMap";
import { BSRPeople, BSRPeopleSpec, BSR_PEOPLE } from "./BSRPeople";
import {
  ColumnLayoutComponent,
  ColumnLayoutSpec,
  COLUMN_LAYOUT,
} from "./ColumnLayout";
import { FilesComponent, FilesComponentSpec, FILES_COMPONENT } from "./Files";
import { GALLERY, GalleryComponent, GallerySpec } from "./Gallery";
import { ImageComponent, ImageSpec, IMAGE_COMPONENT } from "./Image";
import { ImageStackComponent, ImageStackSpec, IMAGE_STACK } from "./ImageStack";
import { LinkList, LinkListSpec, LINK_LIST } from "./LinkList";
import { LoginCallback } from "./LoginCallback";
import { NewsComponent, NewsSpec, NEWS_COMPONENT } from "./News";
import { NotAuthorizedComponent } from "./NotAuthorized";
import { NotFoundComponent } from "./NotFound";
import { PageHome, PageHomeSpec, PAGE_HOME } from "./PageHome";
import { PageNormal, PageNormalSpec, PAGE_NORMAL } from "./PageNormal";
import { PageRedirect, PageRedirectSpec, PAGE_REDIRECT } from "./PageRedirect";
import {
  RichTextComponent,
  RichTextComponentSpec,
  RICH_TEXT_COMPONENT,
} from "./RichText";
import {
  SectionComponent,
  SectionComponentSpec,
  SECTION_COMPONENT,
} from "./Section";
import { TabsComponent, TabsSpec, TABS_COMPONENT } from "./Tabs";
import { TextComponent, TextComponentSpec, TEXT_COMPONENT } from "./Text";
import { UnknownComponent } from "./Unknown";
import {
  FormTmpSpec,
  FORM_TMP,
  FormTmpComponent,
} from "./FormTmp";
import {
  FormMZRSpec,
  FORM_MZR,
  FormMZRComponent,
} from "./Form/FormMZR/FormMZR";
import {
  FormTennisSpec,
  FORM_TENNIS,
  FormTennisComponent,
} from "./Form/FormTennis/FormTennis";
import {
  FormWeiterbildungSpec,
  FORM_WEITERBILDUNG,
  FormWeiterbildungComponent,
} from "./Form/FormWeiterbildung/FormWeiterbildung";
import { FormWeiterbildungBerufsbildnerSpec, FORM_WEITERBILDUNG_BERUFSBILDNER, FormWeiterbildungBerufsbildnerComponent } from "./Form/FormWeiterbildungBerufsbildner/FormWeiterbildungBerufsbildner";
import { FormSchulfestSpec, FORM_SCHULFEST, FormSchulfestComponent } from "./Form/FormSchulfest/FormSchulfest";
import { VideoComponent, VideoSpec, VIDEO_COMPONENT } from "./video/Video";
import { BSRNewsletterComponent, BSRNewsletterComponentSpec, BSR_NEWSLETTER_COMPONENT } from "./BSRNewsletter";

export type ComponentSpec =
  | BSRCalendarSpec
  | TextComponentSpec
  | ColumnLayoutSpec
  | LinkListSpec
  | FilesComponentSpec
  | PageNormalSpec
  | PageRedirectSpec
  | PageHomeSpec
  | ImageSpec
  | RichTextComponentSpec
  | SectionComponentSpec
  | NotFoundComponentSpec
  | NotAuthorizedComponentSpec
  | LoginCallbackSpec
  | TabsSpec
  | NewsSpec
  | BSRPeopleSpec
  | ImageStackSpec
  | GallerySpec
  | SiteMapSpec
  | FormTmpSpec
  | FormMZRSpec
  | FormTennisSpec
  | FormWeiterbildungSpec
  | FormWeiterbildungBerufsbildnerSpec
  | FormSchulfestSpec
  | BSRMapSpec
  | VideoSpec
  | BSRNewsletterComponentSpec;

export type ComponentTypes =
  | typeof TEXT_COMPONENT
  | typeof COLUMN_LAYOUT
  | typeof LINK_LIST
  | typeof FILES_COMPONENT
  | typeof PAGE_NORMAL
  | typeof PAGE_REDIRECT
  | typeof PAGE_HOME
  | typeof IMAGE_COMPONENT
  | typeof RICH_TEXT_COMPONENT
  | typeof SECTION_COMPONENT
  | typeof NOT_FOUND_COMPONENT
  | typeof NOT_AUTHORIZED_COMPONENT
  | typeof LOGIN_CALLBACK
  | typeof BSR_CALENDAR
  | typeof TABS_COMPONENT
  | typeof NEWS_COMPONENT
  | typeof BSR_PEOPLE
  | typeof IMAGE_STACK
  | typeof GALLERY
  | typeof SITE_MAP
  | typeof FORM_TMP
  | typeof FORM_MZR
  | typeof FORM_TENNIS
  | typeof FORM_WEITERBILDUNG
  | typeof FORM_WEITERBILDUNG_BERUFSBILDNER
  | typeof FORM_SCHULFEST
  | typeof BSR_MAP
  | typeof VIDEO_COMPONENT
  | typeof BSR_NEWSLETTER_COMPONENT;

// Resolves complicated circular dependency
let componentCache: any = null;

function initComponentCache() {
  componentCache = {
    [TEXT_COMPONENT]: TextComponent,
    [COLUMN_LAYOUT]: ColumnLayoutComponent,
    [LINK_LIST]: LinkList,
    [PAGE_NORMAL]: PageNormal,
    [PAGE_REDIRECT]: PageRedirect,
    [PAGE_HOME]: PageHome,
    [SECTION_COMPONENT]: SectionComponent,
    [FILES_COMPONENT]: FilesComponent,
    [RICH_TEXT_COMPONENT]: RichTextComponent,
    [IMAGE_COMPONENT]: ImageComponent,
    [NOT_FOUND_COMPONENT]: NotFoundComponent,
    [NOT_AUTHORIZED_COMPONENT]: NotAuthorizedComponent,
    [SITE_MAP]: SiteMapComponent,
    [BSR_CALENDAR]: BSRCalendarComponent,
    [LOGIN_CALLBACK]: LoginCallback,
    [TABS_COMPONENT]: TabsComponent,
    [BSR_PEOPLE]: BSRPeople,
    [IMAGE_STACK]: ImageStackComponent,
    [GALLERY]: GalleryComponent,
    [NEWS_COMPONENT]: NewsComponent,
    [BSR_MAP]: BSRMapComponent,
    [FORM_TMP]: FormTmpComponent,
    [FORM_MZR]: FormMZRComponent,
    [FORM_TENNIS]: FormTennisComponent,
    [FORM_WEITERBILDUNG]: FormWeiterbildungComponent,
    [FORM_WEITERBILDUNG_BERUFSBILDNER]: FormWeiterbildungBerufsbildnerComponent,
    [FORM_SCHULFEST]: FormSchulfestComponent,
    [VIDEO_COMPONENT]: VideoComponent,
    [BSR_NEWSLETTER_COMPONENT]: BSRNewsletterComponent,
  };
}

export function getComponent(type: ComponentTypes): IComponent<ComponentSpec> {
  if (componentCache === null) {
    initComponentCache();
  }
  // This is perfectly type-safe, but TS can't see control-flow dependant types and thus can't
  // understand that the map access above acts as a smart form of type refinement.
  if (componentCache[type] === undefined) {
    return UnknownComponent as any;
  }
  return componentCache[type] as any;
}
