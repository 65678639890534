import { createBrowserHistory } from "history";
import React, { useContext, useEffect, useState } from "react";
import { PageContext } from "./PageContext";

const history = createBrowserHistory();

let navigationInhibitedMessage: string | null = null;

let locationEffectCallbacks: ((newLocation: string) => void)[] = [];

export function useLocation() {
  const [location, setLocation] = useState(history.location.pathname);
  useEffect(() => {
    return history.listen((newLocation) => {
      setLocation(newLocation.location.pathname);
    });
  }, []);
  return location;
}

export function goToLocation(path: string) {
  if (navigationInhibitedMessage !== null) {
    alert(navigationInhibitedMessage);
    return;
  }
  locationEffectCallbacks.forEach((cb) => cb(path));
  history.push(path);
}

export function goToLocationReplace(path: string) {
  if (navigationInhibitedMessage !== null) {
    alert(navigationInhibitedMessage);
    return;
  }
  locationEffectCallbacks.forEach((cb) => cb(path));
  history.replace({
    pathname: path,
    hash: "",
    search: "",
  });
}

export function useInhibitNavigation(message: string) {
  useEffect(() => {
    navigationInhibitedMessage = message;
    return () => {
      navigationInhibitedMessage = null;
    };
  }, [message]);
}

export function useLocationEffect(callback: (newLocation: string) => void) {
  useEffect(() => {
    locationEffectCallbacks.push(callback);
    return () => {
      locationEffectCallbacks = locationEffectCallbacks.filter(
        (cb) => cb !== callback
      );
    };
  }, [callback]);
}

const secureProtocols = ["https://", "http://", "tel:", "mailto:"];

const linkStyle = { color: "inherit", textDecoration: "inherit" };

export function isGoodURL(url: string): boolean {
  if (url.match(/^\/([^/]|$)/g)) return true;
  return secureProtocols.find((proto) => url.startsWith(proto)) !== undefined;
}

export function Link(props: {
  path: string;
  children: React.ReactNode;
  activeColor?: string;
}) {
  const { origin } = useContext(PageContext);
  const location = useLocation();
  if (props.path.match(/^\/([^/]|$)/g)) {
    // Local path, short-circuit routing
    return (
      <a
        href={origin + props.path}
        onClick={(e) => {
          e.preventDefault();
          goToLocation(props.path);
        }}
        style={{
          ...linkStyle,
          color:
            props.activeColor && props.path === location
              ? props.activeColor
              : "inherit",
        }}
      >
        {props.children}
      </a>
    );
  }
  if (props.path.startsWith(origin + "/")) {
    const path = props.path.slice(origin.length);
    // Explicit local path, short-circuit routing
    return (
      <a
        href={props.path}
        onClick={(e) => {
          e.preventDefault();
          goToLocation(path);
        }}
        style={{
          ...linkStyle,
          color:
            props.activeColor && props.path === location
              ? props.activeColor
              : "inherit",
        }}
      >
        {props.children}
      </a>
    );
  }

  const protocol = secureProtocols.find((proto) =>
    props.path.startsWith(proto)
  );
  if (protocol === undefined) {
    // Insecure protocol, don't render link
    console.error(`Insecure protocol for URL '${props.path}', not rendering`);
    return <>{props.children}</>;
  }
  // External safe link
  return (
    <a href={props.path} style={linkStyle}>
      {props.children}
    </a>
  );
}
