import { ComponentSpec } from "./components";

export const pageName = "Berufsschule Rüti";

const PAGE_NORMAL = "page_normal";

export function makeSystemPage(contentSpec: ComponentSpec): ComponentSpec {
  return {
    type: PAGE_NORMAL,
    content: {
      children: [contentSpec],
    },
  };
}
