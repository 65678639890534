export const NOT_FOUND_COMPONENT = "not_found";

export interface NotFoundComponentSpec {
  type: typeof NOT_FOUND_COMPONENT;
  path: string;
}

export const NOT_AUTHORIZED_COMPONENT = "not_authorized";

export interface NotAuthorizedComponentSpec {
  type: typeof NOT_AUTHORIZED_COMPONENT;
  // If the page is protected by AMR restrictions and the available AMRs are insufficient,
  // this list will contain sufficient AMRs.
  sufficientAMRs: string[];
}

export const LOGIN_CALLBACK = "login_callback";

export interface LoginCallbackSpec {
  type: typeof LOGIN_CALLBACK;
}
