import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";

export const BSR_PEOPLE = "bsr_people";

export interface Person {
  name?: string;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
}

export type PersonSortField = "firstName" | "lastName";

export interface BSRPeopleSpec {
  type: typeof BSR_PEOPLE;
  people: Person[];
  sortField: PersonSortField;
  sortDirection: "asc" | "desc";
}

export const Container = styled.div`
  display: table;
  overflow-x: auto;
  margin-top: 15px;
  @media (min-width: 768px) {
    min-width: 768px;
  }
  @media (max-width: 410px) {
    display: block;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PersonContainer = styled.div`
  display: table-row;
  border-top: 1px solid gray;
  @media (max-width: 410px) {
    display: block;
  }
`;

export const Name = styled.div`
  color: #444;
  @media (min-width: 410px) {
    display: table-cell;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #888;
    vertical-align: middle;
  }
  @media (max-width: 410px) {
    display: block;
    padding-top: 5px;
  }
`;

export const Position = styled.div`
  @media (min-width: 410px) {
    display: table-cell;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 1px solid gray;
    text-align: left;
    vertical-align: middle;
  }
  @media (max-width: 410px) {
    display: block;
  }
`;

export const EmailContainer = styled.div`
  @media (min-width: 410px) {
    display: table-cell;
    border-top: 1px solid gray;
    text-align: left;
    vertical-align: middle;
  }
  @media (max-width: 410px) {
    display: block;
    padding-bottom: 5px;
  }
`;

const Component = (props: { spec: BSRPeopleSpec }) => {
  const isMobile = useMediaQuery("(max-width: 768px) and (min-width: 410px)");

  let people = props.spec.people.map((person) => {
    if (person.name !== undefined) {
      person.firstName = person.name.split(" ").slice(0, -1).join(" ");
      person.lastName = person.name.split(" ").slice(-1).join(" ");
      person.name = undefined;
    }
    return person;
  });
  people = sorta(
    people,
    props.spec.sortField ?? "firstName",
    props.spec.sortDirection ?? "asc"
  );
  return (
    <Container>
      {people.map((person) => {
        return (
          <PersonContainer>
            <Name>{person.firstName + " " + person.lastName}</Name>
            <Position>{person.position}</Position>
            <EmailContainer>
              <a
                href={"mailto:" + person.email}
                style={{ color: isMobile ? "inherit" : "auto" }}
              >
                {isMobile ? (
                  <FontAwesomeIcon icon={faEnvelope} />
                ) : (
                  person.email
                )}
              </a>
            </EmailContainer>
          </PersonContainer>
        );
      })}
    </Container>
  );
};

export const BSRPeople: IComponent<BSRPeopleSpec> = {
  component: Component,
};

export function sorta(
  people: Person[],
  field: PersonSortField,
  direction: "asc" | "desc"
) {
  return people.slice().sort(
    (a, b) =>
      a[field].localeCompare(b[field], "de", {
        numeric: true,
        sensitivity: "base",
      }) * (direction === "asc" ? 1 : -1)
  );
}
