import useMediaQuery from "@mui/material/useMediaQuery";
import { default as React, useContext, useState } from "react";
import Sidebar from "react-sidebar";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { PageContext } from "../PageContext";
import { useLocationEffect } from "../router";
import { BSRNavbar, BSRNavbarSide } from "./BSRNavbar";
import { BSRFooter } from "./Footer";
import { Vertical, VerticalDI, verticalDI, VerticalSpec } from "./Vertical";
import { bsrBlue } from "./bsr-defs";

export const PAGE_NORMAL = "page_normal";
export interface PageNormalSpec {
  type: typeof PAGE_NORMAL;
  content: VerticalSpec;
}

const Container = styled.div`
  // singel gradient
  //background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

  // header gradient
  // background: rgb(255,255,255);
  // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 210px, rgba(242,242,242,1) 100%);
`;

const ContentContainer = styled.div`
  background: #f2f2f2;

  min-height: calc(100vh - 150px);
  padding: 30px;
  padding-top: 5px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    min-height: calc(100vh - 150px - 45px);
    padding-left: 180px;
    padding-right: 100px;
  }

  @media (max-width: 1096px) {
    padding-left: 160px;
    padding-right: 80px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const EditButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: 100px;
  top: 170px;
  @media (max-width: 1096px) {
    display: none;
  }
`;

const EditButton = styled.button`
  background-color: ${bsrBlue};
  color: white;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100px;
  height: 30px;
`;

export const PageNormalRender = (props: VerticalDI<PageNormalSpec>) => {
  const [isOpen, setOpen] = useState(false);
  useLocationEffect(() => {
    setOpen(false);
  });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { canEdit, setEditing, isEditing } = useContext(PageContext);
  const VerticalDIComp = props.Vertical;

  const RenderMain = () => (
    <Container>
      <BSRNavbar onOpenSidebar={() => setOpen(true)} />
      <EditButtonContainer>
        {canEdit && !isEditing ? (
          <EditButton onClick={() => setEditing(true)}>Editieren</EditButton>
        ) : null}
      </EditButtonContainer>
      <ContentContainer>
        <VerticalDIComp spec={props.spec.content} />
      </ContentContainer>
      <BSRFooter />
    </Container>
  );

  return isMobile ? (
    <Sidebar
      sidebar={<BSRNavbarSide onCloseSidebar={() => setOpen(false)} />}
      open={isOpen}
      pullRight
      styles={{
        sidebar: {
          zIndex: "6",
        },
        overlay: {
          zIndex: "5",
        },
      }}
      onSetOpen={setOpen}
    >
      <RenderMain />
    </Sidebar>
  ) : (
    <RenderMain />
  );
};

export const PageNormal: IComponent<PageNormalSpec> = {
  component: verticalDI(PageNormalRender, Vertical),
};
