import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";

export const TEXT_COMPONENT = "text";

export interface TextComponentSpec {
  type: typeof TEXT_COMPONENT;
  text: string;
}

export const Container = styled.div``;

export const TextStyle = styled.div`
  white-space: pre-wrap;
  min-height: 12pt;
  margin-top: 15px;
`;

const Text = (props: { spec: TextComponentSpec }) => {
  return (
    <Container>
      <TextStyle>{props.spec.text}</TextStyle>
    </Container>
  );
};

export const TextComponent: IComponent<TextComponentSpec> = {
  component: Text,
};
