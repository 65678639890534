import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import styled from "styled-components/macro";
import {
  deletePage,
  getPage,
  pageMetaListToTree,
  PageTreeItem,
  setPage,
  slugize,
  usePageMeta,
} from "./api";
import { IComponent } from "./Component";
import { ComponentSpec, ComponentTypes } from "./components/index";
import { newEmptyVertical } from "./components/Vertical";
import { isGoodURL, Link } from "./router";

const PAGE_HOME = "page_home";
const PAGE_NORMAL = "page_normal";
const PAGE_REDIRECT = "page_redirect";

const CreatePopoverContainer = styled.div`
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 5px #555;
  border-radius: 5px;
`;

const PageControlContainer = styled.span`
  display: inline-block;
  padding-left: 8px;
  color: #666;
`;

const EditIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const ConfimText = styled.code`
  background-color: #f1f1f1;
  padding: 2px;
`;

function RenderRecursive(props: { page: PageTreeItem }) {
  const [isOpen, setOpen] = useState(false);
  const [popoverContent, setPopoverContent] = useState<
    "Create" | "Modify" | "Delete"
  >("Create");

  return (
    <li>
      <Link path={props.page.path!}>{props.page.title}</Link>
      {props.page.accessPolicy !== null ? (
        <Popover
          isOpen={isOpen}
          onClickOutside={() => {
            setOpen(false);
          }}
          positions={["bottom"]}
          padding={10}
          containerStyle={{ overflow: "visible" }}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={"white"}
              arrowSize={10}
            >
              {popoverContent === "Create" ? (
                <CreateNewPage page={props.page} setOpen={setOpen} />
              ) : popoverContent === "Modify" ? (
                <ModifyPage page={props.page} setOpen={setOpen} />
              ) : popoverContent === "Delete" ? (
                <DeletePage page={props.page} setOpen={setOpen} />
              ) : (
                <></>
              )}
            </ArrowContainer>
          )}
        >
          <span>
            <PageControlContainer>
              <EditIcon
                size="xs"
                icon={faPlus}
                onClick={() => {
                  setPopoverContent("Create");
                  setOpen(true);
                }}
              />
              <EditIcon
                size="xs"
                icon={faEdit}
                onClick={() => {
                  setPopoverContent("Modify");
                  setOpen(true);
                }}
              />
              <EditIcon
                size="xs"
                icon={faTrash}
                onClick={() => {
                  setPopoverContent("Delete");
                  setOpen(true);
                }}
              />
            </PageControlContainer>
          </span>
        </Popover>
      ) : null}
      {props.page.children.length > 0 ? (
        <ul>
          {props.page.children.map((child) => (
            <RenderRecursive page={child} />
          ))}
        </ul>
      ) : null}
    </li>
  );
}

function CreateNewPage(props: {
  page: PageTreeItem;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [newPageText, setNewPageText] = useState("");
  const [newPageType, setNewPageType] = useState<ComponentTypes>(PAGE_HOME);
  const [newRedirectURL, setNewRedirectURL] = useState("");

  return (
    <CreatePopoverContainer>
      <div>
        <b>Add new page under {props.page.title}</b>
      </div>
      <input
        placeholder="New Page Name"
        value={newPageText}
        onChange={(e) => setNewPageText(e.currentTarget.value)}
      />
      <select
        value={newPageType}
        onChange={(e) =>
          setNewPageType(e.currentTarget.value as ComponentTypes)
        }
      >
        <option value={PAGE_NORMAL}>Page Layout Normal</option>
        <option value={PAGE_HOME}>Page Layout with Image</option>
        <option value={PAGE_REDIRECT}>Redirect</option>
      </select>

      {newPageType === PAGE_REDIRECT ? (
        <input
          placeholder="Redirect URL"
          value={newRedirectURL}
          onChange={(e) => setNewRedirectURL(e.currentTarget.value)}
          onBlur={() => {
            if (!isGoodURL(newRedirectURL)) {
              alert("Invalid URL entered");
              setNewRedirectURL("");
            }
          }}
        />
      ) : null}

      <button
        onClick={async () => {
          const content: ComponentSpec =
            newPageType === PAGE_HOME
              ? {
                  type: PAGE_HOME,
                  content: newEmptyVertical(),
                }
              : newPageType === PAGE_NORMAL
              ? {
                  type: PAGE_NORMAL,
                  content: newEmptyVertical(),
                }
              : {
                  type: PAGE_REDIRECT,
                  redirectURL: newRedirectURL,
                };
          await setPage({
            page: {
              accessPolicy: props.page.accessPolicy,
              title: newPageText,
              content,
              description: "",
              publishTime: Math.floor(new Date().valueOf() / 1000),
              revision: 0,
            },
            path: props.page.path?.endsWith("/")
              ? props.page.path! + slugize(newPageText)
              : props.page.path! + "/" + slugize(newPageText),
          });
          setNewPageText("");
          setNewPageType(PAGE_HOME);
          props.setOpen(false);
        }}
      >
        Create
      </button>
    </CreatePopoverContainer>
  );
}

function ModifyPage(props: {
  page: PageTreeItem;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [newPageText, setNewPageText] = useState(props.page.title);
  const [newPageType, setNewPageType] = useState<ComponentTypes>(
    props.page.pageType ?? PAGE_NORMAL
  );
  const [newRedirectURL, setNewRedirectURL] = useState(props.page.redirectURL);

  return (
    <CreatePopoverContainer>
      <div>
        <b>Modify page under {props.page.title}</b>
      </div>
      <input
        placeholder="Page Name"
        value={newPageText}
        onChange={(e) => setNewPageText(e.currentTarget.value)}
      />
      <select
        value={newPageType}
        onChange={(e) =>
          setNewPageType(e.currentTarget.value as ComponentTypes)
        }
      >
        <option value={PAGE_NORMAL}>Page Layout Normal</option>
        <option value={PAGE_HOME}>Page Layout with Image</option>
        <option value={PAGE_REDIRECT}>Redirect</option>
      </select>

      {newPageType === PAGE_REDIRECT ? (
        <input
          placeholder="Redirect URL"
          value={newRedirectURL as string}
          onChange={(e) => setNewRedirectURL(e.currentTarget.value)}
          onBlur={() => {
            if (!isGoodURL(newRedirectURL ?? "")) {
              alert("Invalid URL entered");
              setNewRedirectURL("");
            }
          }}
        />
      ) : null}

      <button
        onClick={async () => {
          if (typeof props.page.path !== "string") {
            console.log("paht not a string");
            return;
          }

          await getPage({ path: props.page.path }).then(async (page) => {
            page.content.type = newPageType;
            if (newPageType === PAGE_REDIRECT) {
              const content: ComponentSpec = {
                type: PAGE_REDIRECT,
                redirectURL:
                  newRedirectURL?.length === 0 ? "/" : newRedirectURL ?? "/",
              };

              page.content = content;
            }

            if (page.title !== newPageText) {
              page.title = newPageText;
              const newPath = props.page.path?.endsWith("/")
                ? props.page.path!.substring(
                    0,
                    props.page.path!.slice(0, -1).lastIndexOf("/")
                  ) +
                  "/" +
                  slugize(newPageText)
                : props.page.path!.substring(
                    0,
                    props.page.path!.lastIndexOf("/")
                  ) +
                  "/" +
                  slugize(newPageText);

              await setPage({
                page: page,
                path: newPath,
              }).then(async (a) => {
                await deletePage({ path: props.page.path! });
              });
            } else {
              await setPage({
                page: page,
                path: props.page.path!,
              });
            }
          });

          props.setOpen(false);
        }}
      >
        Update
      </button>
    </CreatePopoverContainer>
  );
}

function DeletePage(props: {
  page: PageTreeItem;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [newPageText, setNewPageText] = useState("");
  const [errorText, setErrorText] = useState("");

  return (
    <CreatePopoverContainer>
      <div>
        <b>Are you absolutely sure?</b>
      </div>
      <p>
        Enter the following to confirm:{" "}
        <ConfimText>{props.page.title}</ConfimText>
      </p>
      <input
        placeholder={props.page.title}
        value={newPageText}
        onChange={(e) => setNewPageText(e.currentTarget.value)}
      />
      <button
        onClick={async () => {
          if (props.page.title !== newPageText) {
            setErrorText("page name does not match");
            console.log("error");
            return;
          }

          if (typeof props.page.path !== "string") {
            console.log("paht not a string");
            return;
          }

          await deletePage({
            path: props.page.path,
          });

          setNewPageText("");
          props.setOpen(false);
        }}
      >
        Löschen
      </button>
      {errorText === "" ? null : <p>Error: {errorText}</p>}
    </CreatePopoverContainer>
  );
}

const siteMapRequest = { includeContent: false, prefix: "/" };

export const SITE_MAP = "site_map";

export interface SiteMapSpec {
  type: typeof SITE_MAP;
}

export function SiteMap(props: { spec: SiteMapSpec }) {
  const pageMetaStatus = usePageMeta(siteMapRequest);
  const pageTree = useMemo(
    () =>
      pageMetaStatus.pages === null
        ? null
        : pageMetaListToTree(pageMetaStatus.pages!),
    [pageMetaStatus.pages]
  );
  if (pageMetaStatus.loading) {
    return <FontAwesomeIcon spin icon={faSpinner} />;
  }
  if (pageMetaStatus.error !== null) {
    return <span>{pageMetaStatus.error.toString()}</span>;
  }
  return (
    <div>
      <h1>Pages</h1>
      <ul>
        <RenderRecursive page={pageTree!} />
      </ul>
    </div>
  );
}

export const SiteMapComponent: IComponent<SiteMapSpec> = {
  component: SiteMap,
};
