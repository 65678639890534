import React, { useState } from "react";
import { Blurhash } from "react-blurhash";
import Carousel, { Modal, ModalGateway, ViewType } from "react-images";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { getAppropriateImage, Image } from "./image";
import { ImageRenderer } from "./ImageRenderer";

export const IMAGE_STACK = "image_stack";

export interface ImageStackSpec {
  type: typeof IMAGE_STACK;
  images: Image[];
}

export const ImagePlaceholder = styled.div`
  border: 2px dashed #888;
  border-radius: 15px;
  background: white;
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
`;

export const ImageContainer = styled.div`
  display: block;
  margin: 16px;
  max-width: 100%;
  position: relative;
  picture {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    overflow-y: hidden;
    box-shadow: 0 0 14px #000000;
    border-radius: 3px;
    position: relative;
  }
  img {
    display: block;
    max-width: 100%;
    border-radius: 3px;
  }
`;

const Component = (props: { spec: ImageStackSpec }) => {
  const [lighboxOpen, setLightboxOpen] = useState(false);

  if (props.spec.images.length >= 1) {
    return (
      <>
        <ImageContainer>
          {props.spec.images.length > 2 ? (
            <picture
              style={{ height: "25px", width: "80%", opacity: 0.4, zIndex: 0 }}
            >
              <ImageRenderer
                image={props.spec.images[2]}
                onClick={() => setLightboxOpen(true)}
                onlyBlurhash={true}
              />
            </picture>
          ) : null}
          {props.spec.images.length > 1 ? (
            <picture
              style={{ height: "25px", width: "90%", opacity: 0.8, zIndex: 1 }}
            >
              <ImageRenderer
                image={props.spec.images[1]}
                onClick={() => setLightboxOpen(true)}
                onlyBlurhash={true}
              />
            </picture>
          ) : null}
          <picture style={{ opacity: 1, zIndex: 2 }}>
            <ImageRenderer
              image={props.spec.images[0]}
              onClick={() => setLightboxOpen(true)}
            />
          </picture>
        </ImageContainer>
        {/* @ts-ignore */}
        <ModalGateway>
          {lighboxOpen ? (
            <Modal onClose={() => setLightboxOpen(false)}>
              <Carousel
                views={props.spec.images.map(
                  (i): ViewType => ({
                    source: {
                      download: i.baseURL,
                      regular: getAppropriateImage(
                        i,
                        window.innerWidth,
                        window.innerHeight
                      ),
                      fullscreen: getAppropriateImage(
                        i,
                        window.screen.availWidth,
                        window.screen.availHeight
                      ),
                    },
                  })
                )}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
  return <div>Missing Images</div>;
};

export const ImageStackComponent: IComponent<ImageStackSpec> = {
  component: Component,
};
