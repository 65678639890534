import React from "react";

export interface PageContext {
  isEditing: boolean;
  setEditing: (editing: boolean) => void;
  canEdit: boolean;
  origin: string;
}

export const PageContext = React.createContext<PageContext>({
  isEditing: false,
  setEditing: () => {},
  canEdit: false,
  origin: ""
});
