import React, { useEffect, useState } from "react";
import { FieldValues, FormState, UseFormRegister } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components/macro";

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const ErrorMessageBox = styled.div`
  color: red;
`;

const InputElementTextarea = styled.textarea<{ active: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 3rem 1rem 1rem;
  display: block;
  //width: 100%;
  border: 2px solid #949494;
  border-radius: 2px;
  order: 1;
  -webkit-appearance: none;
  min-height: 3rem;
  ${(props) => {
    if (props.active) {
      return `
      outline: 0;
      border-color: #009ee0;
      padding-top: 2rem;
      padding-bottom: 0.75rem;
      `;
    }
  }};
  :placeholder {
    color: #666;
    opacity: 1;
  }
`;

const InputLabelTextarea = styled.label<{ active: boolean }>`
  position: absolute;
  top: 1.2rem;
  left: 1.125rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 1.5rem;
  background: #fff;
  transition: top 0.1s ease;
  color: #666;
  ${(props) => {
    if (props.active) {
      return `
      top: 2px;
      padding-top: .875rem;
      transform: translateY(0);
      font-size: 12px;
      //font-family: HelveticaNowBlack; "Arial Black"; Helvetica; sans-serif;
      font-weight: bold;
      color: #000;
      outline: none;
      `;
    }
  }};
`;

export default function CustomTextarea(props: {
  name: string;
  required: boolean;
  label?: string;
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
}) {
  const [active, setActive] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (props.formState.dirtyFields[props.name]) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [props.formState, props.name]);

  const handleInputFocus = () => {
    setActive(true);
  };

  const handleInputBlur = () => {
    if (dirty === false) {
      setActive(false);
    }
  };

  return (
    <div>
      <InputBox>
        <InputElementTextarea
          {...props.register(props.name, {
            required: props.required,
            onBlur: handleInputBlur,
          })}
          onFocus={handleInputFocus}
          active={active}
        />
        <InputLabelTextarea active={active} htmlFor={props.name}>
          {props.label ? props.label : props.name}
        </InputLabelTextarea>
      </InputBox>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </div>
  );
}
