import useMediaQuery from "@mui/material/useMediaQuery";
import { default as React, useContext, useState } from "react";
import Sidebar from "react-sidebar";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import {
  goToLocationReplace,
  isGoodURL,
  useLocation,
  useLocationEffect,
} from "../router";
import { BSRNavbar, BSRNavbarSide } from "./BSRNavbar";
import { BSRFooter } from "./Footer";
import { PageContext } from "../PageContext";

export const PAGE_REDIRECT = "page_redirect";
export interface PageRedirectSpec {
  type: typeof PAGE_REDIRECT;
  redirectURL: string;
}

const Container = styled.div`
  // singel gradient
  //background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);

  // header gradient
  // background: rgb(255,255,255);
  // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 210px, rgba(242,242,242,1) 100%);
`;

const ContentContainer = styled.div`
  background: #f2f2f2;

  min-height: calc(100vh - 150px);
  padding: 30px;
  padding-top: 5px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    min-height: calc(100vh - 150px - 45px);
    padding-left: 180px;
    padding-right: 100px;
  }

  @media (max-width: 1096px) {
    padding-left: 160px;
    padding-right: 80px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const PageRedirectRender = (props: PageRedirectSpec) => {
  const { origin } = useContext(PageContext);

  let content = <></>;

  if (isGoodURL(props.redirectURL)) {
    // Local path, short-circuit routing
    if (props.redirectURL.match(/^\/([^/]|$)/g)) {
      goToLocationReplace(props.redirectURL);
    } else if (props.redirectURL.startsWith(origin + "/")) {
      // Explicit local path, short-circuit routing
      const path = props.redirectURL.slice(origin.length);
      goToLocationReplace(path);
    } else {
      window.location.replace(props.redirectURL);
    }

    content = <>Redirect to {props.redirectURL}</>;
  } else {
    content = <>Error: Invalid redirect URL {props.redirectURL}</>;
  }

  return (
    <Container>
      <BSRNavbar onOpenSidebar={() => {}} />
      <ContentContainer>{content}</ContentContainer>
      <BSRFooter />
    </Container>
  );
};

export const PageRedirect: IComponent<PageRedirectSpec> = {
  component: ({ spec }) => (
    <PageRedirectRender redirectURL={spec.redirectURL} type={spec.type} />
  ),
};
