import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { NotFoundComponentSpec } from "../standardcomponents";

const Container = styled.div`
  margin-top: 5vh;
  text-align: center;
`;

const LargeHeader = styled.h1`
  font-size: 40px;
`;

const LargeNumber = styled.div`
  font-size: 150px;
  color: #888;
`;

const Component = (props: { spec: NotFoundComponentSpec }) => {
  return (
    <Container>
      <LargeNumber>404</LargeNumber>
      <LargeHeader>Seite nicht gefunden</LargeHeader>
      <div>Keine Seite unter {props.spec.path} gefunden</div>
    </Container>
  );
};

export const NotFoundComponent: IComponent<NotFoundComponentSpec> = {
  component: Component,
};
