import React, { useEffect, useState } from "react";
import { IComponent } from "../Component";
import {
  FieldValues,
  FormState,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components/macro";
import { bsrBlue } from "./bsr-defs";
import { submitForm } from "../api";
import { useLocation } from "../router";

export const FORM_TMP = "form_tmp";

export interface FormTmpSpec {
  type: typeof FORM_TMP;
}

const FormBox = styled.div`
  max-width: 768px;
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const SuccessBox = styled.div`
  max-width: 768px;
  background-color: #1a7f1f;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const ErrorBox = styled.div`
  max-width: 768px;
  background-color: #c00d00;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const Component = () => {
  const location = useLocation();
  const { register, handleSubmit, formState } = useForm();

  const { errors } = formState;

  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const onSubmit = (data: any) => {
    data.path = location;
    data.form_id = "1";

    console.log(data);

    submitForm(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setErr(true);
      });
  };

  if (success) {
    return (
      <SuccessBox>
        <h1>Bestellung erfolgreich</h1>
        <h3>
          Besten Dank für Ihre Bestellung. Sie erhalten in Kürze ein automatisch
          generiertes Bestätigungsmail.
        </h3>
      </SuccessBox>
    );
  }

  if (err) {
    return (
      <ErrorBox>
        <h1>Fehler</h1>
        <h3>
          Es ist ein Fehler bei der Verarbeitung aufgetreten. Bitte probieren
          sie es in einigen Minuten noch einmal oder wenden sie sich an
          office@bsrueti.ch.
        </h3>
      </ErrorBox>
    );
  }

  return (
    <FormBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomRadio
          name="Name"
          values={["Frau", "Herr", "andere"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <CustomInput
          name="Name"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <CustomInput
          name="Vorname"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <CustomInput
          name="Strasse"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <CustomInput
          name="PLZ"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <CustomInput
          name="Ort"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <CustomInput
          name="Email"
          type="email"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <CustomInput
          name="Exemplare"
          label="Anzahl Exemplare zu CHF 10.- (keine Portokosten)"
          type="number"
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <CustomTextarea
          name="Bemerkungen"
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />

        <SubmitBox>
          <SubmitButton type="submit">Bestellen</SubmitButton>
        </SubmitBox>
      </form>
    </FormBox>
  );
};

export const FormTmpComponent: IComponent<FormTmpSpec> = {
  component: Component,
};

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const InputElement = styled.input<{ active: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 3rem 1rem 1rem;
  display: block;
  //width: 100%;
  border: 2px solid #949494;
  border-radius: 2px;
  order: 1;
  -webkit-appearance: none;
  ${(props) => {
    if (props.active) {
      return `
      outline: 0;
      border-color: #009ee0;
      padding-top: 2rem;
      padding-bottom: 0.75rem;
      `;
    }
  }};
  :placeholder {
    color: #666;
    opacity: 1;
  }
`;

const InputLabel = styled.label<{ active: boolean }>`
  position: absolute;
  top: 1.2rem;
  left: 1.125rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 1.5rem;
  background: #fff;
  transition: top 0.1s ease;
  color: #666;
  ${(props) => {
    if (props.active) {
      return `
      top: 0.875rem;
      transform: translateY(0);
      font-size: 12px;
      //font-family: HelveticaNowBlack; "Arial Black"; Helvetica; sans-serif;
      font-weight: bold;
      color: #000;
      outline: none;
      `;
    }
  }};
`;

const ErrorMessageBox = styled.div`
  color: red;
`;

function CustomInput(props: {
  name: string;
  required: boolean;
  label?: string;
  type?: string;
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
}) {
  const [active, setActive] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (props.formState.dirtyFields[props.name]) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [props.formState, props.name]);

  const handleInputFocus = () => {
    setActive(true);
  };

  const handleInputBlur = () => {
    if (dirty === false) {
      setActive(false);
    }
  };

  return (
    <div>
      <InputBox>
        <InputElement
          {...props.register(props.name, {
            required: props.required,
            onBlur: handleInputBlur,
          })}
          type={props.type}
          onFocus={handleInputFocus}
          active={active}
        />
        <InputLabel active={active} htmlFor={props.name}>
          {props.label ? props.label : props.name}
        </InputLabel>
      </InputBox>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </div>
  );
}

const InputElementTextarea = styled.textarea<{ active: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 3rem 1rem 1rem;
  display: block;
  //width: 100%;
  border: 2px solid #949494;
  border-radius: 2px;
  order: 1;
  -webkit-appearance: none;
  min-height: 3rem;
  ${(props) => {
    if (props.active) {
      return `
      outline: 0;
      border-color: #009ee0;
      padding-top: 2rem;
      padding-bottom: 0.75rem;
      `;
    }
  }};
  :placeholder {
    color: #666;
    opacity: 1;
  }
`;

const InputLabelTextarea = styled.label<{ active: boolean }>`
  position: absolute;
  top: 1.2rem;
  left: 1.125rem;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 1.5rem;
  background: #fff;
  transition: top 0.1s ease;
  color: #666;
  ${(props) => {
    if (props.active) {
      return `
      top: 2px;
      padding-top: .875rem;
      transform: translateY(0);
      font-size: 12px;
      //font-family: HelveticaNowBlack; "Arial Black"; Helvetica; sans-serif;
      font-weight: bold;
      color: #000;
      outline: none;
      `;
    }
  }};
`;

function CustomTextarea(props: {
  name: string;
  required: boolean;
  label?: string;
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
}) {
  const [active, setActive] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (props.formState.dirtyFields[props.name]) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [props.formState, props.name]);

  const handleInputFocus = () => {
    setActive(true);
  };

  const handleInputBlur = () => {
    if (dirty === false) {
      setActive(false);
    }
  };

  return (
    <div>
      <InputBox>
        <InputElementTextarea
          {...props.register(props.name, {
            required: props.required,
            onBlur: handleInputBlur,
          })}
          onFocus={handleInputFocus}
          active={active}
        />
        <InputLabelTextarea active={active} htmlFor={props.name}>
          {props.label ? props.label : props.name}
        </InputLabelTextarea>
      </InputBox>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </div>
  );
}

const RadioLabel = styled.label`
  margin-right: 16px;
  line-height: 1.5;
`;

const RadioTitle = styled.label`
  font-size: 12px;
  //font-family: HelveticaNowBlack; "Arial Black"; Helvetica; sans-serif;
  font-weight: bold;
`;

function CustomRadio(props: {
  name: string;
  required: boolean;
  label?: string;
  type?: string;
  values: string[];
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
}) {
  return (
    <div>
      <RadioTitle>{props.label ? props.label : props.name}</RadioTitle>
      <div>
        {props.values.map((v) => (
          <RadioLabel>
            <input
              type="radio"
              value={v}
              {...props.register(props.name, { required: props.required })}
            />
            {v}
          </RadioLabel>
        ))}
      </div>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </div>
  );
}
