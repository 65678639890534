import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { bsrBlue } from "./bsr-defs";
import { getNewsletter, NewsletterResponse } from "../api";
import { useLocation } from "../router";

export const BSR_NEWSLETTER_COMPONENT = "bsr_newsletter";

interface FileItem {
  name: string;
  url: string;
  type: string;
}

export interface BSRNewsletterComponentSpec {
  type: typeof BSR_NEWSLETTER_COMPONENT;
  vertical: boolean;
  dense: boolean;
  newsletter: string;
}

export const BaseContainer = styled.div<{ vertical: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.vertical ? "flex-flow: column;" : "")}
  margin-top: 15px;
`;

export const FileContainer = styled.a<{ dense: boolean }>`
  ${(props) => (props.dense ? "padding: 5px;" : "padding: 10px;")}
  ${(props) => (props.dense ? "margin-bottom: 5px;" : "margin-bottom: 20px;")}
${(props) => (props.dense ? "" : "background: white;")}
${(props) =>
    props.dense
      ? ""
      : "box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);"}

  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

export const BaseExtFile = (props: FileItem & { dense: boolean }) => {
  return (
    <FileContainer href={props.url} dense={props.dense}>
      <FontAwesomeIcon icon={faFilePdf} color={bsrBlue} /> {props.name}
    </FileContainer>
  );
};

const Component = (props: { spec: BSRNewsletterComponentSpec }) => {
  const location = useLocation();
  const [newsletters, setNewsletters] = useState<NewsletterResponse[]>([]);

  useEffect(() => {
    try {
      getNewsletter({
        path: location,
        newsletter: props.spec.newsletter.toLowerCase(),
      }).then((res) => {
        if (res === null) {
          setNewsletters([]);
          return;
        }
        setNewsletters(res);
      });
    } catch (e) {
      console.log(e);
    }
  }, [location, props.spec.newsletter]);

  const files = newsletters.map((n) => {
    return {
      name: n.name
        .replace(props.spec.newsletter.toLowerCase(), "")
        .replace("/", ""),
      url: "https://s3.bsrueti.ch/epaper/" + n.name,
      type: "application/pdf",
    };
  });

  return (
    <BaseContainer vertical={props.spec.vertical}>
      {files.map((file: FileItem, i) => (
        <BaseExtFile key={file.url} {...file} dense={props.spec.dense} />
      ))}
    </BaseContainer>
  );
};

export const BSRNewsletterComponent: IComponent<BSRNewsletterComponentSpec> = {
  component: Component,
};
