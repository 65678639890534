import { Base64 } from "js-base64";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { goToLocationReplace } from "./router";

interface OIDCState {
  lastLocation: string;
  nonce: string;
  codeVerifier: string;
  tokenEndpoint: string;
  clientId: string;
}

export async function signIn() {
  await signInAndRedirect(window.location.pathname);
}


async function sha256(data: string): Promise<string> {
  const rawHash = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(data));
  return Base64.fromUint8Array(new Uint8Array(rawHash), true);
}

export async function signInAndRedirect(pathname: string) {
  const stateToken = nanoid();
  const nonce = nanoid();
  const codeVerifier = nanoid();
  const challenge = await sha256(codeVerifier);
  const idpInfo = await (
    await fetch("/api/idp")
  ).json();

  const stateData: OIDCState = {
    lastLocation: pathname,
    nonce,
    codeVerifier,
    clientId: idpInfo.clientId,
    tokenEndpoint: idpInfo.tokenEndpoint,
  };
  window.sessionStorage.setItem(stateToken, JSON.stringify(stateData));
  const queryParams = new URLSearchParams({
    client_id: idpInfo.clientId,
    redirect_uri: window.location.origin + "/login/callback",
    response_type: "code",
    response_mode: "fragment",
    scope: "openid groups profile",
    code_challenge: challenge,
    code_challenge_method: "S256",
    claims: JSON.stringify({
      id_token: {
        name: null,
        picture: null,
        groups: null,
      }
    }),
    state: stateToken,
    nonce,
  });
  window.location.replace( // TODO: Only add parameters
    idpInfo.authorizationEndpoint + "?" + queryParams
  );
}

let logoutInhibitedMessage: string | null = null;

export function useInhibitLogout(message: string) {
  useEffect(() => {
    logoutInhibitedMessage = message;
    return () => {
      logoutInhibitedMessage = null;
    };
  }, [message]);
}

export function signOut() {
  if (logoutInhibitedMessage !== null) {
    alert(logoutInhibitedMessage);
    return;
  }
  localStorage.removeItem("oidc-token");
  userEffectCallbacks.forEach((cb) => cb());
}

export async function processResponse() {
  const response = new URLSearchParams(window.location.hash.substr(1));
  const stateToken = response.get("state");
  if (stateToken === null) {
    console.log("No state token for OIDC callback");
    goToLocationReplace("/");
    return;
  }

  const stateRaw = window.sessionStorage.getItem(stateToken);
  if (stateRaw === null) {
    console.log("No OIDC login with this state token registered");
    goToLocationReplace("/");
    return;
  }
  sessionStorage.removeItem(stateToken);

  const code = response.get("code");
  if (code === null) {
    console.log("No code for OIDC callback");
    goToLocationReplace("/");
    return;
  }

  try {
    const state = JSON.parse(stateRaw) as OIDCState;
    const tokenRequest = new URLSearchParams({
      client_id: state.clientId,
      code_verifier: state.codeVerifier,
      redirect_uri: window.location.toString().split("#")[0],
      grant_type: "authorization_code",
      code,
    });
    const tokenResponse = (await (await fetch(state.tokenEndpoint, {
      method: 'POST',
      body: tokenRequest.toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })).json());

    const newIDClaim = parseJWT(tokenResponse["id_token"]);
    if (newIDClaim === null) {
      console.log("Invalid ID token passed for OIDC callback");
      goToLocationReplace("/");
      return;
    }
    if (newIDClaim.nonce !== state.nonce) {
      console.log("Invalid OIDC nonce, probably a replay");
      goToLocationReplace("/");
      return;
    }

    localStorage.setItem("oidc-token", tokenResponse["id_token"]);
    goToLocationReplace(state.lastLocation);
  } catch (err) {
    alert("Authentication error, try again: " + err)
    goToLocationReplace("/");
    return;
  }
  userEffectCallbacks.forEach((cb) => cb());
}

interface IDClaim {
  sub: string;
  iss: string;
  aud: string;
  exp: number;
  iat: number;
  amr: string[];
  nonce: string;
  name: string;
  picture?: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email?: string;
  email_verified?: boolean;
  updated_at: number;
  groups: string[];
}

function parseJWT(jwt: string): IDClaim | null {
  const jwtParts = jwt.split(".");
  if (jwtParts.length !== 3) {
    return null;
  }
  const [, contentRaw] = jwtParts;
  return JSON.parse(Base64.decode(contentRaw));
}

export function getUser(): IDClaim | null {
  const token = localStorage.getItem("oidc-token");
  if (token === null) {
    return null;
  }

  const tokenVal = parseJWT(token);
  if (tokenVal === null) {
    return null;
  }

  if (Math.floor(new Date().valueOf() / 1000) >= tokenVal.exp) {
    localStorage.removeItem("oidc-token");
    userEffectCallbacks.forEach((cb) => cb());
    return null;
  }
  return tokenVal;
}

let userEffectCallbacks: (() => void)[] = [];

export function useUser() {
  const [user, setUser] = useState(getUser());
  useEffect(() => {
    const callback = () => {
      setUser(getUser());
    };
    userEffectCallbacks.push(callback);
    return () => {
      userEffectCallbacks = userEffectCallbacks.filter((cb) => cb !== callback);
    };
  });
  return user;
}

export function getIDToken(): string | null {
  const token = localStorage.getItem("oidc-token");
  if (token === null) {
    return null;
  }
  const tokenVal = parseJWT(token);
  if (tokenVal === null) {
    return null;
  }
  if (Math.floor(new Date().valueOf() / 1000) >= tokenVal.exp) {
    return null;
  }
  return token;
}
