import React from "react";
import { FieldValues, FormState, UseFormRegister } from "react-hook-form";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components/macro";
import "moment/locale/de";

const CheckboxBox = styled.label`
  display: flex;
  flex-direction: column;
`;

const CheckboxSpace = styled.div`
  margin-top: 15px;
`;

const RadioTitle = styled.label`
  font-size: 14px;
  font-weight: bold;
`;

const RadioLabel = styled.label`
  margin-right: 16px;
  line-height: 1.5;
`;

const ErrorMessageBox = styled.div`
  color: red;
`;

export default function CustomCheckbox(props: {
  name: string;
  required: boolean;
  label?: string;
  type?: string;
  values: string[];
  register: UseFormRegister<FieldValues>;
  errors: any;
  formState: FormState<Record<string, any>>;
}) {
  return (
    <CheckboxSpace>
      <RadioTitle>{props.label ? props.label : props.name}</RadioTitle>
      <CheckboxBox>
        {props.values.map((v) => (
          <RadioLabel>
            <input
              type="checkbox"
              value={v}
              {...props.register(props.name, { required: props.required })}
            />
            {v}
          </RadioLabel>
        ))}
      </CheckboxBox>
      <ErrorMessageBox>
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          message="Dies ist ein Pflichtfeld."
        />
      </ErrorMessageBox>
    </CheckboxSpace>
  );
}
