import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { useUser } from "../oidc";
import { NotAuthorizedComponentSpec } from "../standardcomponents";
import { LoginButton } from "./LoginButton";

const Container = styled.div`
  margin-top: 5vh;
  text-align: center;
`;

const LargeHeader = styled.h1`
  font-size: 40px;
`;

const LargeNumber = styled.div`
  font-size: 150px;
  color: #888;
`;

const Component = (props: { spec: NotAuthorizedComponentSpec }) => {
  const user = useUser();
  return (
    <Container>
      <LargeNumber>403</LargeNumber>
      <LargeHeader>Zugriff verboten</LargeHeader>
      <div>Sie haben keine Berechtigung auf diese Seite zuzugreifen</div>
      <div style={{ marginBottom: 30 }} />
      {user === null ? <LoginButton /> : null}
    </Container>
  );
};

export const NotAuthorizedComponent: IComponent<NotAuthorizedComponentSpec> = {
  component: Component,
};
