import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import { bsrBlue } from "../../bsr-defs";
import { submitForm } from "../../../api";
import { useLocation } from "../../../router";
import "moment/locale/de";
import CustomRadio from "../components/CustomRadio";
import CustomTextarea from "../components/CustomTextarea";
import CustomInput from "../components/CustomInput";
import { IComponent } from "../../../Component";

export const FORM_SCHULFEST = "form_schulfest";

export interface FormSchulfestSpec {
  type: typeof FORM_SCHULFEST;
}

const MultiLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const MultiLineGrow = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const MultiLineGrowSecond = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  min-width: 0;
`;

const SuccessBox = styled.div`
  max-width: 768px;
  background-color: #1a7f1f;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const ErrorBox = styled.div`
  max-width: 768px;
  background-color: #c00d00;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const FormBox = styled.div`
  max-width: 768px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const PersonButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Component = () => {
  const location = useLocation();
  const { register, watch, handleSubmit, formState, control } = useForm();
  const { errors } = formState;
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [count, setCount] = useState(1);

  const teilnameState = watch("Teilnahme");

  const onSubmit = (data: any) => {
    data.path = location;
    data.form_id = "6";

    console.log(data);

    submitForm(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setErr(true);
      });
  };

  if (success) {
    return (
      <SuccessBox>
        <h1>Anmeldung erfolgreich</h1>
        <h3>
          Besten Dank für Ihre Anmeldung. Sie erhalten in Kürze eine
          Bestätigungsmail.
        </h3>
      </SuccessBox>
    );
  }

  if (err) {
    return (
      <ErrorBox>
        <h1>Fehler</h1>
        <h3>
          Es ist ein Fehler bei der Verarbeitung aufgetreten. Bitte probieren
          sie es in einigen Minuten noch einmal oder wenden sie sich an
          sandra.gubser@bsrueti.ch.
        </h3>
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBox>
        <h1>Anmeldung Schulfest</h1>
        <h3>Angaben zur Person</h3>
        <CustomRadio
          name={"Anrede"}
          label="Anrede"
          values={["Frau", "Herr", "andere"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <MultiLine>
          <MultiLineGrow>
            <CustomInput
              name={"Vorname"}
              label="Vorname"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrow>
          <MultiLineGrowSecond>
            <CustomInput
              name={"Nachname"}
              label="Nachname"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Email"
          type="email"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomRadio
          name={"Teilnahme"}
          label="Teilnahme"
          values={["ich komme alleine", "mit mir kommt folgende Person mit:"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
          horizontal={true}
        />
        {teilnameState === "mit mir kommt folgende Person mit:" ? (
          <div style={{ marginLeft: "30px" }}>
            <CustomRadio
              name={"Anrede_Partner"}
              label="Anrede"
              values={["Frau", "Herr", "andere"]}
              register={register}
              required={true}
              errors={errors}
              formState={formState}
            />
            <MultiLine>
              <MultiLineGrow>
                <CustomInput
                  name={"Vorname_Partner"}
                  label="Vorname"
                  register={register}
                  required={true}
                  errors={errors}
                  control={control}
                />
              </MultiLineGrow>
              <MultiLineGrowSecond>
                <CustomInput
                  name={"Nachname_Partner"}
                  label="Nachname"
                  register={register}
                  required={true}
                  errors={errors}
                  control={control}
                />
              </MultiLineGrowSecond>
            </MultiLine>
          </div>
        ) : null}
        <SectionTitle>Bemerkungen</SectionTitle>
        <CustomTextarea
          name="Bemerkungen"
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <p>
          Sie erhalten nach dem Anmelden eine Mailbestätigung. Sollten Sie diese
          nicht erhalten, müssen Sie die Anmeldung nochmals erfassen oder ein
          Mail an sandra.gubser@bsrueti.ch schreiben.
        </p>
        <SubmitBox>
          <SubmitButton type="submit">Anmelden</SubmitButton>
        </SubmitBox>
      </FormBox>
    </form>
  );
};

export const FormSchulfestComponent: IComponent<FormSchulfestSpec> = {
  component: Component,
};
