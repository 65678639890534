import { RawDraftContentState } from "draft-js";
import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import DraftJSView from "./draftjs-view";
import "./RichTextExt.css";
import { bsrBlue } from "./bsr-defs";

export const RICH_TEXT_COMPONENT = "rich_text";

export interface RichTextComponentSpec {
  type: typeof RICH_TEXT_COMPONENT;
  content: RawDraftContentState;
  id?: string;
}

export const EditorStyle = styled.div`
  margin-top: 15px;
  white-space: pre-wrap;

  ul {
    margin: 1em 0 1em 0;
    padding: 0 0 0 1em;
    /* make space for li's :before */
    list-style: none;
    li {
      position: relative;
      padding-left: 0.4em;
    }
    li:before {
      content: "\f054"; /* FontAwesome Unicode */
      color: ${bsrBlue};
      font-family: RichTextListIcon;
      position: absolute;
      left: -1em;
      top: 0.15em;
      transform: scale(0.9);
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    li {
      display: block;
    }
    li:before {
      content: counter(item) ". ";
      counter-increment: item;
      color: ${bsrBlue};
      display: block;
      position: relative;
      max-height: 0px;
      left: -1.3em;
      top: 0.05em;
    }
  }
`;

const RichText = (props: { spec: RichTextComponentSpec }) => {
  return (
    <EditorStyle>
      <DraftJSView data={props.spec.content} />
    </EditorStyle>
  );
};

export const RichTextComponent: IComponent<RichTextComponentSpec> = {
  component: RichText
};
