import React from "react";
import styled from "styled-components/macro";
import { Component } from "./Component";
import { ComponentSpec } from "./components";

const PageContainer = styled.div`
height: 100vh;
`;

export const Page = React.memo((props: { spec: ComponentSpec }): JSX.Element => {
  return (
    <PageContainer>
      <Component spec={props.spec} />
    </PageContainer>
  );
})
