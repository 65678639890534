import { useMemo } from "react";
import { Blurhash } from "react-blurhash";
import useResizeObserver from "use-resize-observer";
import { getAppropriateImage, Image } from "./image";

export function ImageRenderer(props: {
  image: Image;
  maxHeight?: number;
  maxWidth?: number;
  onClick?: (e: React.MouseEvent) => void;
  onlyBlurhash?: boolean;
}): JSX.Element {
  if (props.image.dimensionsProcessed) {
    return <ProcessedImageRenderer {...props} />;
  }
  return (
    <img
      src={props.image.baseURL}
      onClick={props.onClick}
      style={{
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
        width: props.image.width ? props.image.width : "100%",
        height: props.image.height,
      }}
    />
  );
}

function ProcessedImageRenderer(props: {
  image: Image;
  maxHeight?: number;
  maxWidth?: number;
  onClick?: (e: React.MouseEvent) => void;
  onlyBlurhash?: boolean;
}): JSX.Element {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const { image, maxHeight, maxWidth, onClick, onlyBlurhash } = props;

  const imageSection = useMemo(() => {
    if (width === undefined || height === undefined) {
      return <></>;
    }

    return (
      <img
        src={getAppropriateImage(image, width, height)}
        height={height}
        width={width}
      />
    );
  }, [width, height, image]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      style={{
        position: "relative",
        aspectRatio:
          props.image.width && props.image.height
            ? `${props.image.width} / ${props.image.height}`
            : "auto",
        overflow: "hidden",
        maxWidth: maxWidth === 0 ? undefined : maxWidth,
        maxHeight: maxHeight === 0 ? undefined : maxHeight,
      }}
    >
      <div style={{ position: "absolute" }}>
        {width && height && props.image.blurHash ? (
          <Blurhash hash={props.image.blurHash} width={width} height={height} />
        ) : null}
      </div>
      {onlyBlurhash ? null : (
        <div style={{ position: "absolute" }}>{imageSection}</div>
      )}
    </div>
  );
}
