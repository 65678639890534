import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { processResponse } from "../oidc";
import { LoginCallbackSpec } from "../standardcomponents";

const Container = styled.div`
  margin-top: 5vh;
  text-align: center;
`;

const Component = (props: { spec: LoginCallbackSpec }) => {
  processResponse();
  return <Container></Container>;
};

export const LoginCallback: IComponent<LoginCallbackSpec> = {
  component: Component,
};
