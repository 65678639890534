import useMediaQuery from "@mui/material/useMediaQuery";
import { default as React, useContext, useState } from "react";
import Sidebar from "react-sidebar";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { PageContext } from "../PageContext";
import { useLocationEffect } from "../router";
import { BSRNavbar, BSRNavbarSide } from "./BSRNavbar";
import ImageSonnenplatzMobile from "./Sonnenplatz-Mobile.jpg";
import ImageSonnenplatz from "./Sonnenplatz.jpg";
import { Vertical, VerticalDI, verticalDI, VerticalSpec } from "./Vertical";
import { bsrBlue } from "./bsr-defs";

export const PAGE_HOME = "page_home";
export interface PageHomeSpec {
  type: typeof PAGE_HOME;
  content: VerticalSpec;
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

const ContentContainer = styled.div`
  background: #f2f2f2;
  // min-height: calc(100vh - 155px);
  padding: 30px;
  padding-top: 0px;
  flex: 1 1 auto;
  @media (min-width: 768px) {
    // min-height: calc(100vh - 215px);
    padding-left: 180px;
    padding-right: 100px;
  }

  @media (max-width: 1096px) {
    padding-left: 160px;
    padding-right: 80px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

// flex: none is a workaround for older IE versions.
// It does nothing on newer browsers.
const MainImage = styled.img`
  max-width: 100%;
  display: block;
  flex: none;
`;

const EditButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: 30px;
  top: 110px;
  @media (max-width: 1096px) {
    display: none;
  }
`;

const EditButton = styled.button`
  background-color: ${bsrBlue};
  color: white;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100px;
  height: 30px;
`;

export const PageHomeRender = (props: VerticalDI<PageHomeSpec>) => {
  const [isOpen, setOpen] = useState(false);
  useLocationEffect(() => {
    setOpen(false);
  });
  const isMobile = useMediaQuery("(max-width: 768px)");

  // TODO: Move?
  const { canEdit, setEditing, isEditing } = useContext(PageContext);

  const VerticalDIComp = props.Vertical;

  const RenderMain = () => (
    <Container>
      <BSRNavbar onOpenSidebar={() => setOpen(true)} />
      <EditButtonContainer>
        {canEdit && !isEditing ? (
          <EditButton key={1} onClick={() => setEditing(true)}>
            Editieren
          </EditButton>
        ) : null}
      </EditButtonContainer>
      <MainImage src={isMobile ? ImageSonnenplatzMobile : ImageSonnenplatz} />
      <ContentContainer>
        <VerticalDIComp spec={props.spec.content} />
      </ContentContainer>
    </Container>
  );

  return isMobile ? (
    <Sidebar
      sidebar={<BSRNavbarSide onCloseSidebar={() => setOpen(false)} />}
      open={isOpen}
      pullRight
      styles={{
        sidebar: {
          zIndex: "6",
        },
        overlay: {
          zIndex: "5",
        },
      }}
      onSetOpen={setOpen}
    >
      <RenderMain />
    </Sidebar>
  ) : (
    <RenderMain />
  );
};

export const PageHome: IComponent<PageHomeSpec> = {
  component: verticalDI(PageHomeRender, Vertical),
};
