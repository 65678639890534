import React from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";
import { Vertical, VerticalSpec } from "./Vertical";

export const SECTION_COMPONENT = "section";
export interface SectionComponentSpec {
  type: typeof SECTION_COMPONENT;
  title: string;
  child: VerticalSpec;
}

export const Container = styled.section``;

export const Title = styled.h1`
  border-radius: 5px;
  min-width: 50px;
  min-height: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-block-end: 0px;
`;

const SectionRender = (props: { spec: SectionComponentSpec }) => {
  return (
    <Container>
      <Title>{props.spec.title}</Title>
      <Vertical spec={props.spec.child} name={props.spec.title} />
    </Container>
  );
};

export const SectionComponent: IComponent<SectionComponentSpec> = {
  component: SectionRender,
};
