import React from "react";
import styled from "styled-components/macro";
import { signIn } from "../oidc";
import { bsrBlue } from "./bsr-defs";

const LoginButtonContainer = styled.button`
  background-color: ${bsrBlue};
  color: white;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 140px;
  height: 30px;
`;

export function LoginButton() {
  return (
    <LoginButtonContainer onClick={() => signIn()}>Login</LoginButtonContainer>
  );
}
