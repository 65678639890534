import React, { Suspense } from "react";
import styled from "styled-components/macro";
import { IComponent } from "../Component";

export const BSR_MAP = "bsr_map";

export interface BSRMapSpec {
  type: typeof BSR_MAP;
  customMaxWidth: number;
  customMaxHeight: number;
  viewport: viewport;
  displayMode: displayMode;
}
export interface viewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
  // transitionInterpolator?: TransitionInterpolator;
  // transitionDuration?: number | "auto";
}

export type displayMode = "car" | "footpath" | "default";

export const MapContainer = styled.div<{ maxWidth: number; maxHeight: number }>`
  display: block;
  margin-top: 15px;
  width: ${(props) => props.maxWidth + "px"};
  height: ${(props) => props.maxHeight + "px"};
  position: relative;
`;

const BSRMapGL = React.lazy(() => import("./BSRMapGL"));

const Component = (props: { spec: BSRMapSpec }) => {
  return (
    <>
      <MapContainer
        maxWidth={props.spec.customMaxWidth}
        maxHeight={props.spec.customMaxHeight}
      >
        <Suspense fallback={<span>Loading...</span>}>
          <BSRMapGL spec={props.spec} />
        </Suspense>
      </MapContainer>
    </>
  );
};

export const BSRMapComponent: IComponent<BSRMapSpec> = {
  component: Component,
};
