import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { useFetch } from "use-http";
import { IComponent } from "../Component";
import { ResponseEvent } from "./bsr_calendar";
import { BaseExtFile } from "./Files";

export const BSR_CALENDAR = "bsr_calendar";
export interface BSRCalendarSpec {
  type: typeof BSR_CALENDAR;
  descriptionsAvailable?: boolean;
  filteringEnabled?: boolean;
  maximumEvents?: number;
}

const ComponentContainer = styled.div`
  overflow-x: hidden;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const EventsContainer = styled.div`
  @media (min-width: 768px) {
    flex-grow: 1;
    min-width: 0;
  }
`;

const EventDateContainer = styled.div`
  padding: 10px;
  font-weight: bold;
  min-width: 150px;
  @media (min-width: 768px) {
    flex-shrink: 0;
  }
`;

const EventContainer = styled.div<{ emph: boolean }>`
  background-color: ${(props) => (props.emph ? "#dcdcdc" : "none")};
`;

const EventHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EventDate = styled.div`
  display: flex;
`;

const EventTitle = styled.div`
  padding: 10px;
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

const EventsTable = styled.div`
  width: 100%;
  min-width: 0;
`;

const GraphicalBracket = styled.div`
  background-image: linear-gradient(#666, #666), linear-gradient(#666, #666);

  background-repeat: no-repeat;
  background-size: 6px 2px;
  background-position: top right, bottom right;

  border: solid #666;
  text-align: justify;
  border-width: 0 2px;
  border-left: 0;
  padding: 5px 5px;
  margin: 6px 0 7px 2px;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const OpenCloseButton = styled.button`
  border: none;
  background: none;
  outline: none;
  :focus {
    color: #444;
  }
`;

const Location = styled.div`
  color: #565656;
  font-size: 0.8em;
`;

const DescriptionContainer = styled.div`
  overflow-x: scroll;
  padding: 5px;
  @media (min-width: 768px) {
    margin-left: 170px;
  }
`;

function RenderEvent(props: {
  event: ResponseEvent;
  emph: boolean;
  descriptionsAvailable: boolean;
}): JSX.Element {
  const startTime = DateTime.fromISO(props.event.start_time);
  const endTime = DateTime.fromISO(props.event.end_time);

  const [isOpen, setOpen] = useState(false);

  return (
    <EventContainer emph={props.emph}>
      <EventHeaderContainer>
        <EventDateContainer>
          {props.event.is_all_day ? (
            <RenderTimeAllDay startTime={startTime} endTime={endTime} />
          ) : (
            <>
              <EventDate>
                <div>
                  {startTime.toLocaleString(
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    },
                    { locale: "de-CH" }
                  )}
                  <br />
                  {endTime.toLocaleString(
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    },
                    { locale: "de-CH" }
                  )}
                </div>
                <GraphicalBracket />
              </EventDate>
            </>
          )}
        </EventDateContainer>
        <EventTitle>
          <div>
            <div>{props.event.title}</div>
            <Location>{props.event.location}</Location>
          </div>
          <div>
            {props.descriptionsAvailable &&
            props.event.description !== undefined &&
            props.event.description.trim().length > 0 ? (
              <OpenCloseButton onClick={() => setOpen(!isOpen)}>
                <FontAwesomeIcon
                  size="2x"
                  icon={isOpen ? faAngleDown : faAngleRight}
                />
              </OpenCloseButton>
            ) : null}
          </div>
        </EventTitle>
      </EventHeaderContainer>
      {isOpen ? (
        <DescriptionContainer>
          <div
            dangerouslySetInnerHTML={{ __html: props.event.description }}
            style={{
              maxWidth: "100%",
            }}
          />
          <FilesContainer>
            {(props.event.attachments ?? []).map((a) => (
              <BaseExtFile
                url={a.url}
                name={a.name}
                type={a.content_type}
                dense={false}
              />
            ))}
          </FilesContainer>
        </DescriptionContainer>
      ) : null}
    </EventContainer>
  );
}

function RenderTimeAllDay({
  startTime,
  endTime,
}: {
  startTime: DateTime;
  endTime: DateTime;
}): JSX.Element {
  // FYI: The API returns all-day times as UTC 0:00, so to avoid
  // incorrect date shifts to the user's locale, force UTC output
  const newEndTime = endTime.minus({ day: 1 });
  if (
    newEndTime.day === startTime.day &&
    newEndTime.month === startTime.month &&
    newEndTime.year === startTime.year
  )
    return (
      <>
        {startTime.toLocaleString(
          {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "utc",
          },
          { locale: "de-CH" }
        )}
      </>
    );
  else
    return (
      <EventDate>
        <div>
          {startTime.toLocaleString(
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              timeZone: "utc",
            },
            { locale: "de-CH" }
          )}
          <br />
          {newEndTime.toLocaleString(
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              timeZone: "utc",
            },
            { locale: "de-CH" }
          )}
        </div>
        <GraphicalBracket />
      </EventDate>
    );
}

const FilterItem = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? "#0080ff" : "#aaa")};
  color: ${(props) => (props.active ? "white" : "black")};
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
  :hover {
    background-color: ${(props) => (props.active ? "#2d94fc" : "#969696")};
  }
`;
const FilterItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  width: 250px;
  min-width: 250px;
`;

function Filter(props: {
  events: ResponseEvent[];
  filter: string[];
  removeFilter: (oldFilter: string) => void;
  addFilter: (newFilter: string) => void;
  clearFilters: () => void;
}): JSX.Element {
  const events = props.events;

  const availableFilters = useMemo(() => {
    const aF = new Set<string>();
    events.forEach((e) => e.categories.forEach((c) => aF.add(c)));
    return [...aF];
  }, [events]);

  return (
    <FilterContainer>
      <h2>Filter</h2>
      <FilterItemContainer>
        <FilterItem
          active={props.filter.length === 0}
          onClick={() => props.clearFilters()}
        >
          Alle
        </FilterItem>
        {availableFilters.map((filter) => (
          <FilterItem
            active={props.filter.indexOf(filter) !== -1}
            onClick={() => {
              if (props.filter.indexOf(filter) !== -1)
                props.removeFilter(filter);
              else props.addFilter(filter);
            }}
          >
            {filter}
          </FilterItem>
        ))}
      </FilterItemContainer>
    </FilterContainer>
  );
}

export const Component = (props: { spec: BSRCalendarSpec }) => {
  const descriptionsAvailable = props.spec.descriptionsAvailable ?? false;
  const url = descriptionsAvailable
    ? "/exchangeapi/calendarview?calendar=schulkalender@bsrueti.ch"
    : "/exchangeapi/calendarview?short=true&calendar=schulkalender@bsrueti.ch";
  const { loading, error, data } = useFetch<ResponseEvent[]>(url, {}, [url]);
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const fullFilter = [...userFilter]; // TODO: Property Filter
  const eventFilter =
    fullFilter.length > 0
      ? (e: ResponseEvent) =>
          e.categories.find((c) => fullFilter.indexOf(c) !== -1) !== undefined
      : () => true;
  return (
    <ComponentContainer>
      {(props.spec.filteringEnabled ?? false) && Array.isArray(data) ? (
        <Filter
          events={data}
          filter={userFilter}
          addFilter={(filter) => setUserFilter([...userFilter, filter])}
          removeFilter={(filter) =>
            setUserFilter(userFilter.filter((i) => i !== filter))
          }
          clearFilters={() => setUserFilter([])}
        />
      ) : null}
      <EventsContainer>
        <h2>Termine</h2>
        {error && "Error"}
        {loading && "Loading..."}
        <EventsTable>
          {Array.isArray(data)
            ? data
                .filter(eventFilter)
                .slice(0, props.spec.maximumEvents ?? 5)
                .map((event, i) => (
                  <RenderEvent
                    descriptionsAvailable={
                      props.spec.descriptionsAvailable ?? false
                    }
                    emph={i % 2 === 0}
                    event={event}
                  />
                ))
            : null}
        </EventsTable>
      </EventsContainer>
    </ComponentContainer>
  );
};

export const BSRCalendarComponent: IComponent<BSRCalendarSpec> = {
  component: Component,
};
